<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          :headers="contetTypesHeaders"
          hide-default-footer
          :items="contentTypes"
          item-key="id"
          group-by="contentType.id"
          :items-per-page="-1"
          :search="search"
          class="capitalize "
        >
          <!--eslint-disable -->

          <template v-slot:group.header="{ groupBy, group, isOpen, toggle }">
            <td :colspan="contetTypesHeaders.length">
              <v-icon @click="toggle">
                {{ isOpen ? "mdi-minus" : "mdi-plus" }}
              </v-icon>
              <span v-if="group">{{ getContentTypeById(group).type }}</span>
              <span v-else><b>Root</b></span>
            </td>
          </template>

          <template v-slot:item.contentTypeCategories="{ item }">
            <span
              v-for="(contentTypeCategory, index) in item.contentTypeCategories"
              :key="index"
            >
              -
              {{
                getContentCategoryNameById(
                  contentTypeCategory.contentCategoryId
                )
              }}
              <br />
            </span>
          </template>

          <template v-slot:item.isActive="{ item }">
            <v-icon color="green" v-if="item.isActive">
              mdi-checkbox-marked-circle
            </v-icon>
            <v-icon color="red" v-else> mdi-close-circle </v-icon>
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <v-dialog v-model="addContentTypeDialog" max-width="850px">
                <template v-slot:activator="{ on, attrs }">
                  <v-spacer></v-spacer>

                  <v-btn
                    color="primary custom-button"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="clearContentTypeCategoriesArray"
                  >
                    <v-icon dark>mdi-plus</v-icon>

                    New Type
                  </v-btn>

                  <v-card-title>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                    />
                  </v-card-title>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-form v-model="valid" ref="contentTypeForm">
                      <v-container>
                        <v-row v-if="editedContentType">
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedContentType.type"
                              label="Type"
                              :rules="required"
                            />
                          </v-col>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedContentType.menuTitle"
                              label="Menu Title"
                              :rules="required"
                            />
                          </v-col>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedContentType.postDateConfig"
                              label="Post Date"
                            />
                          </v-col>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedContentType.maxUploadImages"
                              label="Number Of Upload Images"
                              type="number"
                            />
                          </v-col>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedContentType.desktopImageDescriptionConfig"
                              label="Desktop Image Description"
                            />
                          </v-col>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedContentType.tabletImageDescriptionConfig"
                              label="Tablet Image Description"

                            />
                          </v-col>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedContentType.mobileImageDescriptionConfig"
                              label="Mobile Image Description"
                            />
                          </v-col>

                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedContentType.noOfAllowedPosts"
                              label="Number Of Allowed Posts"
                              type="number"
                            />
                          </v-col>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedContentType.maxUploadFiles"
                              label="Number Of Upload Files"
                              type="number"
                            />
                          </v-col>

                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedContentType.titleConfig"
                              label="field1"
                            />
                          </v-col>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedContentType.customTitleConfig1"
                              label="field2"
                            />
                          </v-col>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedContentType.customTitleConfig2"
                              label="field3"
                            />
                          </v-col>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedContentType.shortDescriptionConfig"
                              label="field4"
                            />
                          </v-col>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedContentType.fullDescriptionConfig"
                              label="field5"
                            />
                          </v-col>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="
                                editedContentType.customDescriptionConfig
                              "
                              label="field6"
                            />
                          </v-col>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="
                                editedContentType.htmlDescriptionConfig
                              "
                              label="HTML Description"
                            />
                          </v-col>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedContentType.postCodeConfig"
                              label="Post Code"
                            />
                          </v-col>
                        </v-row>
                        <v-card>
                          <v-container fluid>
                            <v-row align="center" class="m-1 pl-1">
                              <v-col cols="12">
                                <v-select
                                  v-model="options"
                                  :items="optionalFeatures"
                                  chips
                                  label="Optional Features"
                                  multiple
                                  item-text="name"
                                  return-object
                                ></v-select>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>
                        <v-spacer></v-spacer>
                        <v-btn
                          v-if="contentCategories.length > 0"
                          small
                          @click="addCategoryToContentType"
                          class="mt-4 custom-button"
                          color="black white--text"
                        >
                          + Add category
                        </v-btn>
                        <ContentTypeCategoryForm
                          :contentCategoryArray.sync="contentCategoryArray"
                          ref="contentTypeCategoryForm"
                          @validationContentCategoryForm="validationForm"
                        />
                      </v-container>
                    </v-form>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="red darken-1"
                      class="custom-button"
                      text
                      @click="closeAddTypeDialog"
                      :disabled="addTypeLoading"
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      color="blue darken-1 white--text"
                      class="custom-button"
                      @click="addContentType"
                      :disabled="!valid || addTypeLoading || !categoryFormValid"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>

            <v-dialog v-model="changeStatusDialog" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">
                  Are you sure you want to change status of this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="red darken-1"
                    text
                    @click="closeChangeStatusDialog"
                    :disabled="loading"
                    class="custom-button"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :disabled="loading"
                    color="blue darken-1 white--text"
                    class="custom-button"
                    @click="changeActiveStatusConfirm"
                  >
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip
              bottom
              v-if="!addTypeLoading && item.contentType === null"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon small @click="addChild(item)" v-bind="attrs" v-on="on">
                  mdi-plus-network
                </v-icon>
              </template>
              <span>Add child</span>
            </v-tooltip>

            <v-icon
              small
              v-if="
                !item.isActive && (!item.parentId || item.contentType.isActive)
              "
              @click="changeActiveStatus(item)"
              class="ml-1"
            >
              mdi-check
            </v-icon>
            <v-icon
              small
              v-if="
                item.isActive && (!item.parentId || item.contentType.isActive)
              "
              @click="changeActiveStatus(item)"
              class="ml-1"
            >
              mdi-close
            </v-icon>
          </template>

          <template v-slot:item.type="{ item }">
            <span class="capitalize ">{{ item.type }}</span>
          </template>

          <template v-slot="{ item }">
            <draggable :list="contentTypes" tag="tbody">
              <tr v-for="(item, index) in contentTypes" :key="index">
                <td v-if="!addTypeLoading && item.contentType === null">
                  <v-icon small class="page__grab-icon">
                    mdi-arrow-all
                  </v-icon>
                </td>
                <td></td>
              </tr>
            </draggable>
          </template>

          <template v-slot:item.edit="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  @click="editContentType(item)"
                  :contentCategoryArray="contentCategoryArray"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </template>
        </v-data-table>

        <v-snackbar v-model="snackbar">
          {{ snakbarText }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="snackbar = false"
              class="custom-button"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ContentType from "../../models/content-type";
import { mapGetters } from "vuex";
import settingsMixin from "../../mixins/settings";
import ContentTypeCategoryForm from "../../components/ContentTypeCategoryForm.vue";
import ContentTypeCategory from "../../models/content-type-category";
import draggable from "vuedraggable";

export default {
  name: "ContentTypeListing",
  components: {
    ContentTypeCategoryForm,
    draggable,
  },
  mixins: [settingsMixin],
  data() {
    return {
      options: [],
      contentCategoryArray: [],
      search: "",
      parentId: null,
      contetTypesHeaders: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "selected",
          width: "150px",
        },
        {
          text: "Type",
          align: "start",
          sortable: false,
          value: "menuTitle",
          width: "150px",
        },
        {
          text: "Categories",
          align: "start",
          sortable: false,
          value: "contentTypeCategories",
          width: "150px",
        },
        {
          text: "Title Config",
          align: "start",
          sortable: false,
          value: "titleConfig",
        },
        {
          text: "Custom Title Config1",
          align: "start",
          sortable: false,
          value: "customTitleConfig1",
        },
        {
          text: "Custom Title Config2",
          align: "start",
          sortable: false,
          value: "customTitleConfig2",
        },
        {
          text: "Short desc. Config",
          align: "start",
          sortable: false,
          value: "shortDescriptionConfig",
        },
        {
          text: "Full desc. config",
          align: "start",
          sortable: false,
          value: "fullDescriptionConfig",
        },
        {
          text: "Custom desc. Config",
          align: "start",
          sortable: false,
          value: "customDescriptionConfig",
        },
        {
          text: "HTML desc. Config",
          align: "start",
          sortable: false,
          value: "htmlDescriptionConfig",
        },
        {
          text: "Post date. config",
          align: "start",
          sortable: false,
          value: "postDateConfig",
        },
        {
          text: "Allowed Posts No.",
          align: "center",
          sortable: false,
          value: "noOfAllowedPosts",
        },
        {
          text: "Max Upload Files",
          align: "center",
          sortable: false,
          value: "maxUploadFiles",
        },
        {
          text: "Max Upload Images",
          align: "center",
          sortable: false,
          value: "maxUploadImages",
        },
        {
          text: "Desktop Image Description",
          align: "center",
          sortable: false,
          value: "desktopImageDescriptionConfig",
        },
        {
          text: "Tablet Image Description",
          align: "center",
          sortable: false,
          value: "tabletImageDescriptionConfig",
        },
        {
          text: "Mobile Image Description",
          align: "center",
          sortable: false,
          value: "mobileImageDescriptionConfig",
        },
        {
          text: "Post Code",
          align: "center",
          sortable: false,
          value: "postCodeConfig",
        },

        {
          text: "Active",
          align: "center",
          sortable: false,
          value: "isActive",
        },
        {
          text: "Edit",
          align: "center",
          sortable: false,
          value: "edit",
        },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
      editedIndex: -1,
      editedContentType: new ContentType(),
      defaultContentType: new ContentType(),
      addContentTypeDialog: false,
      isCategoriesAllowedForType: false,
      changeStatusDialog: false,
      loading: false,
      addTypeLoading: false,
      snackbar: false,
      snakbarText: ``,
      selectedOptionalFeature: null,
      valid: false,
      categoryFormValid: true,
      required: [(v) => !!v || "this field is required"],
    };
  },
  computed: {
    ...mapGetters("contentManagement", [
      "contentCategories",
      "contentTypesCategories",
      "contentTypeWithMenuCategoryNull",
      "newlyAddedContentType",
      "optionalFeatures",
    ]),
    formTitle() {
      return this.editedContentType.id != undefined ? "Edit Item" : "New Item";
    },
  },
  optionalFeaturesName() {
    return this.optionalFeatures.map((item) => item.name);
  },
  async mounted() {
    this.editedContentType = Object.assign({}, this.defaultContentType);
    await this.loadOptionalFeatures();
  },
  methods: {
    async editContentType(contentType) {
      this.editedContentType = contentType;
      this.options = this.editedContentType.optionalFeatureContentTypes.map(
        (a) => a.optionalFeature
      );
      this.addContentTypeDialog = true;
      if (contentType.contentTypeCategories.length > 0) {
        for (let item in this.editedContentType.contentTypeCategories) {
          this.contentCategoryArray.push({
            ...this.editedContentType.contentTypeCategories[item],
          });
        }
      } else {
        this.contentCategoryArray = [];
      }
    },
    validationForm(value) {
      this.categoryFormValid = value;
    },
    clearContentTypeCategoriesArray() {
      this.contentCategoryArray = [];
    },
    addCategoryToContentType() {
      this.contentCategoryArray.push(new ContentTypeCategory());
    },
    contentCategorySelected(value) {
      this.editContentTypeCategory.contentCategory = value;
    },
    async updateContentTypeOptionalFeatures(contentTypeId) {
      let payload = {
        contentTypeId: contentTypeId,
        optionalFeaturesArray: this.options,
      };
      await this.$store.dispatch(
        "contentManagement/updateContentTypeOptionalFeatures",
        payload
      );
    },
    addChild(item) {
      this.isCategoriesAllowedForType = this.addContentTypeDialog = true;
      this.parentId = item.id;
    },
    closeAddTypeDialog() {
      this.contentCategoryArray = [];
      this.options = [];
      this.addContentTypeDialog = this.isCategoriesAllowedForType = false;
      this.$nextTick(() => {
        this.editedContentType = Object.assign({}, this.defaultContentType);
        this.editedIndex = -1;
      });
    },
    async addContentType() {
      let formData = new FormData();
      formData.append("id", this.selectedOptionalFeature);

      try {
        this.addTypeLoading = true;
        this.editedContentType.contentType = this.parentId
          ? { id: this.parentId }
          : null;
        this.$store.commit(
          "contentManagement/setNewlyAddedContentTypeParent",
          this.editedContentType.contentType
        );

        if (this.editedContentType.id) {
          if (this.editedContentType.parentId) {
            this.editedContentType.contentType = {
              id: this.editedContentType.parentId,
            };
          }

          await this.$store.dispatch(
            "contentManagement/updateContentType",
            this.editedContentType
          );
          await this.updateContentTypeOptionalFeatures(
            this.editedContentType.id
          );
        } else {
          let contentType = await this.$store.dispatch(
            "contentManagement/addContentType",
            this.editedContentType
          );

          await this.updateContentTypeOptionalFeatures(contentType.id);
        }

        await this.updateContentTypeCategories();
        this.$refs.contentTypeForm.resetValidation();
        this.snakbarText = "Content Type Added Successfully";
      } catch (error) {
        this.snackbarText = error;
      } finally {
        this.contentCategoryArray = [];
        this.addTypeLoading = this.addContentTypeDialog = false;
        this.parentId = null;
        this.editedContentType = Object.assign({}, this.defaultContentType);
        this.showSnackbar = true;
      }
    },
    async loadOptionalFeatures() {
      await this.$store.dispatch("contentManagement/loadOptionalFeatures");
    },
    async updateContentTypeCategories() {
      if (this.editedContentType.id == undefined) {
        for (let item in this.contentCategoryArray) {
          this.contentCategoryArray[
            item
          ].contentType = this.newlyAddedContentType;
        }
      } else {
        for (let item in this.contentCategoryArray) {
          this.contentCategoryArray[item].contentType = this.editedContentType;
        }
      }

      let contentTyparArrayNotEmpty = this.contentCategoryArray.filter(
        (a) => a.contentCategory != null
      );

      if (contentTyparArrayNotEmpty.length > 0) {
        let payload = {
          contentType: this.editedContentType,
          contentTypeCategories: contentTyparArrayNotEmpty.map((item) => {
            return { ...item, contentCategoryId: item.contentCategory.id };
          }),
        };
        await this.$store.dispatch(
          "contentManagement/updateContentTypeCategories",
          payload
        );
      }
      if (
        this.editedContentType.id != undefined &&
        contentTyparArrayNotEmpty.length == 0
      ) {
        let payload = {
          contentType: this.editedContentType,
          contentTypeCategories: this.contentCategoryArray,
        };
        await this.$store.dispatch(
          "contentManagement/updateContentTypeCategories",
          payload
        );
      }

      await this.loadContentTypes();
    },
    closeChangeStatusDialog() {
      this.changeStatusDialog = false;
      this.$nextTick(() => {
        this.editedContentType = Object.assign({}, this.defaultContentType);
        this.editedIndex = -1;
      });
    },
    changeActiveStatus(item) {
      this.editedIndex = this.contentTypes.indexOf(item);
      this.editedContentType = Object.assign({}, item);
      this.changeStatusDialog = true;
    },
    async changeActiveStatusConfirm() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "contentManagement/updateContentTypeStatus",
          {
            id: this.editedContentType.id,
            isActive: !this.editedContentType.isActive,
          }
        );
        this.snakbarText = "Content type update successfully";
      } catch (error) {
        this.snackbarText = error;
      } finally {
        this.loading = false;
        this.closeChangeStatusDialog();
        this.snackbar = true;
      }
    },
    async loadContentTypes() {
      await this.$store.dispatch("contentManagement/loadContentTypes");
    },
  },
  watch: {
    addContentTypeDialog(val) {
      val || this.closeAddTypeDialog();
    },
    categoryFormValid(val) {
      this.categoryFormValid = val;
    },
    changeStatusDialog(val) {
      val || this.closeChangeStatusDialog();
    },
  },
};
</script>
<style scoped>
.capitalize {
  text-transform: capitalize;
}
.custom-button {
  border-radius: 3.125rem;
}
.v-data-table {
  font-size: large;
  color: black;
}
.container {
  padding: 0;
}
</style>
