<template>
  <v-container fluid>
    <v-row justify="end">
      <v-spacer />
      <v-spacer />
      <v-spacer />
      <v-spacer />
      <v-col lg="5" sm="5">
        <v-select
          :items="getPageCategoryName"
          label="Select Page Category"
          persistent-hint
          return-object
          single-line
          dense
          outlined
          v-model="pageCategorySelected"
          @change="selectPageCategory"
        />
      </v-col>

      <v-col>
        <v-btn
          color="primary "
          dark
          class="mb-2 custom-button"
          @click="selectedPageEmpty()"
        >
          <v-icon dark>mdi-plus</v-icon>
          New Page
        </v-btn>
      </v-col>
      <!-- <v-col lg="3" sm="3" class="order-btn-position">
				<v-btn color="blue darken-1 white--text" small @click="saveSortedPages">
					Save order
				</v-btn>
			</v-col> -->
    </v-row>

    <!-- DATATABLE -->
    <v-row>
      <v-col>
        <v-data-table
          hide-default-footer
          :headers="pagesHeaders"
          :items-per-page="pageSize"
          :items="pages"
          sort-by="name"
          class="capitalize "
        >
          <template v-slot:body>
            <draggable :list="pages" tag="tbody">
              <tr v-for="(item, index) in pages" :key="index">
                <td>
                  <v-icon small class="page__grab-icon">
                    mdi-arrow-all
                  </v-icon>
                </td>
                <td class="text-cell" v-if="item.name">
                  {{ item.name }}
                </td>
                <td v-else></td>

                <td
                  class="text-cell"
                  v-if="item.localizations[defaultLanguage.locale]"
                >
                  {{ item.localizations[defaultLanguage.locale].title }}
                </td>
                <td v-else></td>

                <td
                  class="text-cell"
                  v-if="item.localizations[defaultLanguage.locale]"
                >
                  {{ item.localizations[defaultLanguage.locale].description }}
                </td>
                <td v-else></td>
                <td
                  class="text-cell"
                  v-if="item.localizations[defaultLanguage.locale]"
                >
                  {{ item.localizations[defaultLanguage.locale].keyWord }}
                </td>
                <td v-else></td>

                <td class="text-cell">
                  {{ item.imgUrl }}
                </td>
                <td class="text-cell">
                  {{ item.imgAlt }}
                </td>
                <td class="text-cell">
                  {{ item.indexing }}
                </td>
                <td class="text-cell">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <!-- <router-link :to="/page/ + item.id"> -->
                      <v-icon
                        small
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="EditPage(item)"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                </td>
              </tr>
            </draggable>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Pagination -->
    <v-row
      class="justify-end mt-6 align-center"
      v-if="seoPaginated.totalNumberOfPages > 1"
    >
      <div class="mr-2">
        <v-select
          class="page-size-selector"
          label="page size"
          outlined
          dense
          hide-details
          :items="pageSizeOptions"
          :value="10"
          @change="pageSize = parseInt($event, 10)"
        />
      </div>
      <div class="mr-2">
        <span>{{ pageNumber }}</span>
        <span class="mx-1">From</span>
        <span>{{ seoPaginated.totalNumberOfPages }}</span>
      </div>
      <v-pagination
        class="align-self-start py-2"
        v-model="pageNumber"
        :length="seoPaginated.totalNumberOfPages"
      />
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  data() {
    return {
      pageSize: 10,
      pageNumber: 1,
      pageCategorySelected: null,
      pageSizeOptions: [5, 10, 15, 20],
      pagesHeaders: [
        {
          align: "start",
          sortable: false,
          value: "sort",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Title", value: "title" },
        { text: "Description", value: "description" },
        { text: "KeyWord", value: "keyWord" },
        { text: "Image URL", value: "imgUrl" },
        { text: "Image Alt", value: "imgAlt" },
        { text: "Indexing", value: "indexing" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  async mounted() {
    await this.loadPages();
  },
  components: {
    draggable,
  },
  watch: {
    async pageNumber() {
      await this.loadPages();
    },
    async pageSize() {
      if (this.pageSize < 1 || this.pageSize > 20) {
        this.pageSize = 10;
      }
      await this.loadPages();
    },
  },

  computed: {
    ...mapGetters("page", ["seoPaginated", "pageCategory"]),
    ...mapGetters("contentManagement", ["defaultLanguage"]),
    pages: {
      get() {
        return this.seoPaginated.result;
      },
    },
    getPageCategoryName() {
      return this.pageCategory.map((item) => item.name);
    },
  },
  methods: {
    selectPageCategory(item) {
      let pageCategory = this.pageCategory.find((x) => x.name == item);
      let payload = {
        pageCategory: pageCategory.id,
        page: {
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
        },
      };
      this.$store.dispatch("page/findAllByPageCategoryId", payload);
    },
    saveSortedPages() {
      try {
        this.$store.dispatch(
          "page/updateOrderNumber",
          this.pages.map((item, index) => {
            return {
              id: item.id,
              orderNumber: (this.pageNumber - 1) * this.pageSize + index + 1,
            };
          })
        );
        this.snackbarText = "Pages order updated successfully";
      } catch (error) {
        this.snackbarText = error;
      } finally {
        this.snackbar = true;
      }
    },
    selectedPageEmpty() {
      this.$store.commit("page/setselectedPageEmpty");
      this.$router.push({ name: "CreatePage"})
    },
    EditPage(item) {
      this.$store.dispatch("page/editPage", item);
      this.$router.push({ name: "EditPage", params: { id: item.id } });
    },
    async loadPages() {
      let payload = {
        locale: await this.defaultLanguage.locale,
        page: {
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
        },
      };
      await this.$store.dispatch("page/loadSeoPaginated", payload);
    },
  },
};
</script>
<style lang="scss">
.text-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.page-size-selector {
  width: 75px;
}
.order-btn-position {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-right: 2rem;
}
.custom-button {
  border-radius: 3.125rem;
}
.v-data-table {
  font-size: large;
  color: black;
}
</style>
