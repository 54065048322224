import PageListing from "../page/views/pages/PageListing.vue";
import PageForm from "../page/views/pages/PageForm.vue";

export const pageRouters = [
  {
    path: "/page",
    name: "PageListing",
    component: PageListing,
  },
  {
    path: "/page/create",
    name: "CreatePage",
    component: PageForm,
  },
  {
    path: "/page/:id",
    name: "EditPage",
    component: PageForm,
  }
];
