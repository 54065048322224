<template>
  <Fragment>
    <v-container>
      <v-row>
        <v-col cols="12"> <h2>Page</h2> </v-col>
        <!-- before tabs -->
        <v-col cols="12">
          <v-row>
            <v-col lg="5" sm="5">
              <v-select
                :items="getPageCategoryName"
                label="Select Page Category"
                persistent-hint
                return-object
                single-line
                dense
                outlined
                v-model="pageCategorySelected"
                @change="selectPageCategory"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="pageForm.name"
                type="text"
                :class="{ 'page-title': selectedPage }"
              >
                <template v-slot:label>
                  Page name
                  <span class="mandatory-field">*</span>
                </template>
              </v-text-field>

              <v-text-field v-model="pageForm.pageUrl" type="text">
                <template v-slot:label>
                  Page Url
                  <span class="mandatory-field">*</span>
                </template>
              </v-text-field>
              <v-img
                v-if="pageForm.imgUrl"
                :src="pageForm.imgUrl"
                aspect-ratio="2"
                class="img-url"/>
              <template>
                <v-file-input
                  v-model="files"
                  prepend-icon="mdi-camera"
                  label="Upload your files"
                />
              </template>
              <v-text-field v-model="pageForm.imgAlt" type="text">
                <template v-slot:label>
                  Image Alterantive
                  <span class="mandatory-field">*</span>
                </template>
              </v-text-field>

              <!-- <v-row>
                <v-col cols="6">
                  <v-checkbox
                    v-model="pageForm.indexing"
                    label="Indexing"
                    color="primary"
                    hide-details
                  ></v-checkbox>
                </v-col> 
                </v-row> -->
            </v-col>
            <v-col cols="12" v-if="!loading" class="d-flex flex-row-reverse">
              <!-- <v-btn
                color="primary"
                @click="savePageObject"
              >
                Save page
              </v-btn> -->
            </v-col>
          </v-row>
        </v-col>
        <!-- start tabss -->
        <v-col>
          <v-tabs
            class="zero-padding"
            v-model="tab"
            background-color="grey darken-3"
            dark
          >
            <v-tabs-slider color="blue darken-6" />
            <v-tab v-for="(item, i) in formLocalizedPages" :key="i">
              <v-icon small class="mr-1">mdi-file-document-box</v-icon>
              {{ getLanguageByLocale(item.locale).description }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-form v-model="pageFormValid" ref="tenantForm">
              <v-tab-item
                v-for="(localizedpageForm, i) in formLocalizedPages"
                :key="i"
              >
                <v-card flat>
                  <v-card-text>
                    <!-- <v-text-field
											v-model="localizedpageForm.name"
											:rules="required"
											type="text"
											:class="{ 'page-title': selectedPage }"
										>
											<template v-slot:label>
												Page Name
												<span class="mandatory-field">*</span>
											</template>
										</v-text-field> -->

                    <v-text-field
                      v-model="localizedpageForm.title"
                      :rules="required"
                      type="text"
                    >
                      <template v-slot:label>
                        Page Title
                        <span class="mandatory-field">*</span>
                      </template>
                    </v-text-field>

                    <VoerroTagsInput
                      v-model="localizedpageForm.selectedTags"
                      placeholder="Keywords"
                    />
                    <v-text-field
                      v-model="localizedpageForm.description"
                      type="text"
                    >
                      <template v-slot:label>
                        Page Description
                        <span class="mandatory-field">*</span>
                      </template>
                    </v-text-field>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-form>
          </v-tabs-items>
        </v-col>
        <!-- end tabss -->
        <v-col cols="12" v-if="!loading" class="d-flex flex-row-reverse">
          <v-btn
            color="black darken-1 white--text "
            class="custom-button "
            @click="submitForm"
            :disabled="!pageFormValid || !pageForm.name"
          >
            Save
          </v-btn>
        </v-col>
        <v-col v-else class="d-flex flex-row-reverse" cols="12">
          <v-progress-circular indeterminate color="deep-orange lighten-2" />
        </v-col>
      </v-row>

      <v-snackbar v-model="showSnackbar" absolute>
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            class="custom-button "
            color="pink"
            text
            v-bind="attrs"
            @click="showSnackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-overlay :value="loading" />
    </v-container>
  </Fragment>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.6.12/vue.min.js"></script>
<script src="https://cdn.jsdelivr.net/npm/@voerro/vue-tagsinput@2.7.1/dist/voerro-vue-tagsinput.js"></script>
<script>
import { mapGetters } from "vuex";
import VoerroTagsInput from "@voerro/vue-tagsinput";
import { Fragment } from "vue-fragment";
import { Language, Page, LocalizedPage } from "../../models/index";
import settingsMixin from "../../../settings/mixins/settings";
import urlHelper from "../../../../mixins/url-helper";

export default {
  name: "PageForm",
  components: { VoerroTagsInput, Fragment },
  mixins: [settingsMixin, urlHelper],
  data() {
    return {
      selectedPageCategoryObject: null,
      formLocalizedPages: [],
      pageCategorySelected: null,
      files: {},
      pageForm: new Page(),
      pageName: null,
      loading: false,
      pageFormValid: true,
      required: [(v) => !!v || "This field is required"],
      showSnackbar: false,
      snackbarText: "",
      selectedTags: [],
      tags: [],
      pageId: null,
      tab: null,
      secettt: null,
    };
  },

  computed: {
    ...mapGetters("page", [
      "selectedPage",
      "selectLocalizedPage",
      "pageCategory",
    ]),
    getKeywords() {
      for (let item in this.formLocalizedPages) {
        this.tags = [];
        this.formLocalizedPages[item].keywords.forEach((element) => {
          this.tags.push(element.value);
          let unique = [...new Set(this.tags)];
          return unique.join(",");
        });
      }
    },
    getPageCategoryName() {
      return this.pageCategory.map((item) => item.name);
    },
  },
  async mounted() {
    await this.setDataToUI();
  },
  methods: {
    selectPageCategory(item) {
      this.selectedPageCategoryObject = this.pageCategory.find(
        (a) => a.name == item
      );
    },
    async editPage() {
      this.pageForm.pageCategory = this.selectedPageCategoryObject;
      await this.$store.dispatch("page/editPage", this.pageForm);
    },
    addUpdatePage(page) {
      this.selectLocalizedPage?.length > 0
        ? this.editLocalizedPage()
        : this.addLocalizationPage(page);
    },
    async setDataToUI() {
      this.formLocalizedPages = [];
      this.constructLocalizedPagesWithLanguages(this.languages);
      if(this.selectedPage != null || this.$route.params.id != null){
      await this.setSelectedPage();
      await this.setLocalizedPageData();
      }
    },
    async setSelectedPage() {
      await this.$store.dispatch("page/loadPageById",this.$route.params.id);
      if (this.selectedPage.id) {
        this.pageForm = this.selectedPage;
      }
      if (this.selectLocalizedPage?.id) {
        this.formLocalizedPages = [...this.selectLocalizedPage];
      }
      if (this.selectedPage.pageCategoryId != null) {
        let selectedPageCategory = this.pageCategory.find(
          (a) => a.id == this.selectedPage.pageCategoryId
        );
        this.pageCategorySelected = selectedPageCategory?.name
          ? selectedPageCategory.name
          : null;
      }
    },

    async setLocalizedPageData() {
      await this.$store.dispatch(
        "page/loadLocalizedPageById",
      this.$route.params.id
      );
      this.formLocalizedPages = this.selectLocalizedPage.map((item) => {
        return {
          ...item,
          selectedTags:
            item.keyWord?.split(",")?.map((i) => {
              return { key: "", value: i };
            }) ?? [],
          language: this.getLanguageByLocale(item.locale),
        };
      });
      // this.pageName = this.selectLocalizedPage[0].name;

      const addedLanguages = [
        ...new Map(
          this.formLocalizedPages.map((item) => [item["locale"], item.locale])
        ).values(),
      ];
      var missedLanguages = this.languages.filter(
        (item) => !addedLanguages.find((element) => element === item.locale)
      );
      if (missedLanguages?.length)
        this.constructLocalizedPagesWithLanguages(missedLanguages);
    },

    constructLocalizedPagesWithLanguages(languages) {
      for (let i = 0; i < languages?.length; i++) {
        let newLocalizedPost = new LocalizedPage({
          id: null,
          language: new Language(languages[i]),
          locale: languages[i].locale,
          title: "",
          selectedTags: [],
        });
        this.formLocalizedPages.push(newLocalizedPost);
      }
    },

    async addPage() {
      this.pageForm.pageCategory = this.selectedPageCategoryObject;
      return await this.$store.dispatch("page/addPage", this.pageForm);
    },
    async editLocalizedPage() {
      try {
        for (let item in this.formLocalizedPages) {
          this.formLocalizedPages[item].page = null;

          let array = [];

          this.formLocalizedPages[item].selectedTags?.forEach((element) =>
            array.push(element.value)
          );
          this.formLocalizedPages[item].keyWord = array.join(",");

          let payload = {
            id: this.formLocalizedPages[item].id,
            data: this.formLocalizedPages[item],
          };
          await this.$store.dispatch("page/editLocalizedPage", payload);
       
        }
        this.showSnackbar = true;
        this.snackbarText = "Data updated Successfully";
      } catch (error) {
        this.showSnackbar = true;
        this.snackbarText = error;
      }
    },
    async addLocalizationPage(page) {
      try {
        for (let item in this.formLocalizedPages) {
          this.formLocalizedPages[item].page = page;
          this.formLocalizedPages[item].name = this.pageName;
          let array = [];
          this.formLocalizedPages[item]?.selectedTags?.forEach((element) =>
            array.push(element.value)
          );
          this.formLocalizedPages[item].keyWord = array.length >0? array.join(",") : null;

          await this.$store.dispatch(
            "page/addLocalizedPage",
            this.formLocalizedPages[item]
          );
          this.showSnackbar = true;
          this.snackbarText = "Data added Successfully";         
        }
        if (this.$route.name === "CreatePage")
          this.$router.push({
            name: "EditPage",
            params: { id: this.selectedPage.id },
          });
        await this.setLocalizedPageData();

      } catch (error) {
        this.showSnackbar = true;
        this.snackbarText = error;
      }
    },
    async submitForm() {
      this.loading = true;
      this.errors = {};

      let newPage = null;
      if (this.selectedPage?.id) {
        newPage = this.selectedPage;
        await this.editPage();
      } else {
        newPage = await this.addPage();
      }

      this.addUpdatePage(newPage);

      if (this.files.name) {
        const formData = new FormData();
        formData.append("pageImage", this.files);
        await this.$store.dispatch("page/uploadImg", {
          pageId: this.pageForm.id,
          formData: formData,
        });
      }

      this.loading = false;
    },
  },
  watch: {
    async languages() {
      await this.setDataToUI();
    },
  async  $route() {
      await this.setDataToUI();
    },
  },
};
</script>

<style scoped>
@import "https://cdn.jsdelivr.net/npm/@voerro/vue-tagsinput@2.7.1/dist/style.css";
.page-title {
  pointer-events: none;
  color: gray;
  opacity: 0.6;
}
.img-url {
  width: 30%;
  margin: 1rem;
}
.custom-button {
  border-radius: 3.125rem;
}
</style>
